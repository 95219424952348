import { formatAsUSD } from '@/lib/util';
import { Tag, Badge, Tooltip } from 'antd';
import moment from 'moment';
import { InformationCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

const Config = [
	{
		title: 'Doc. #',
		dataIndex: 'No_',
		key: 'No_',
		align: 'center',
		width: '7%'
	},
	{
		title: 'Alert',
		dataIndex: 'Alerts',
		key: 'Alerts',
		align: 'center',
		width: '4%',
		render: (text, record) => (
			<>
				<Tooltip
					placement="topLeft"
					title={
						record.WEI_Handling_Description
							? `HANDLING INSTRUCTIONS: ${record.WEI_Handling_Description}`
							: ''
					}
				>
					<Badge
						count={
							record.WEI_Handling_Description ? (
								<InformationCircleIcon className="w-6 h-6 text-yellow-500 hover:cursor-pointer" />
							) : (
								0
							)
						}
					/>
				</Tooltip>
				<Tooltip placement="topLeft" title={record.WEI_High_Priority_Flag ? 'HIGH PRIORITY!' : ''}>
					<Badge
						count={
							record.WEI_High_Priority_Flag ? (
								<ExclamationCircleIcon className="w-6 h-6 text-red-500 hover:cursor-pointer" />
							) : (
								0
							)
						}
					/>
				</Tooltip>
			</>
		)
	},
	{
		title: 'Status',
		dataIndex: 'WEI_Treasury_Status',
		key: 'WEI_Treasury_Status',
		align: 'center',
		width: '9%',
		render: (record) => {
			if (record === 'Pending Approval') {
				return <Tag color="blue">{'Pending'}</Tag>;
			} else if (record === 'Pending Final Approval') {
				return <Tag color="turquoise">{'Pending Final'}</Tag>;
			} else if (record === 'Released') {
				return <Tag color="yellow">{'Released'}</Tag>;
			} else if (record === 'Needs Review') {
				return <Tag color="red">{'Needs Review'}</Tag>;
			} else if (record === 'Posted') {
				return <Tag color="green">{'Posted'}</Tag>;
			} else if (record === '') {
				return <Tag color="orange">{'CSS / Accounting'}</Tag>;
			} else {
				return <Tag color="orange">{record}</Tag>;
			}
		}
	},
	{
		title: 'Entity',
		dataIndex: 'WEI_Company_Name',
		key: 'WEI_Company_Name',
		align: 'left',
		width: '12%',
		ellipsis: {
			showTitle: false
		},
		render: (text) => (
			<Tooltip placement="topLeft" title={text}>
				{text}
			</Tooltip>
		)
	},

	{
		title: 'Invoice #',
		dataIndex: 'VendorInvoiceNo_',
		key: 'VendorInvoiceNo_',
		align: 'left',
		width: '10%'
	},

	{
		title: 'Vendor',
		dataIndex: 'PaytoName',
		key: 'PaytoName',
		align: 'left',
		width: '15%',
		ellipsis: {
			showTitle: false
		},
		render: (text) => (
			<Tooltip placement="topLeft" title={text}>
				{text}
			</Tooltip>
		)
	},
	{
		title: 'Amount',
		dataIndex: 'Amount',
		key: 'Amount',
		align: 'right',
		render: (text, record) => <p className="font-bold">{formatAsUSD(text, record.WEI_Currency)}</p>,
		width: '10%'
	},
	{
		title: 'Posting',
		dataIndex: 'PostingDate',
		key: 'PostingDate',
		align: 'center',
		render: (record) => <p>{record && moment(record).format('MM/DD/YYYY')}</p>,
		width: '10%'
	},
	{
		title: 'Bank Name',
		dataIndex: 'WEI_Pay_From_Bank_Name',
		key: 'WEI_Pay_From_Bank_Name',
		align: 'left',
		width: '15%',
		ellipsis: {
			showTitle: false
		},
		render: (text) => (
			<Tooltip placement="topLeft" title={text}>
				{text}
			</Tooltip>
		)
	},
	{
		title: 'Relationship',
		dataIndex: 'WEI_UDF1_Value',
		key: 'WEI_UDF1_Value',
		align: 'left',
		width: '10%',
		ellipsis: {
			showTitle: false
		},
		render: (text) => (
			<Tooltip placement="topLeft" title={text}>
				{text}
			</Tooltip>
		)
	},
	{
		title: 'Type',
		dataIndex: 'PaymentMethodCode',
		key: 'PaymentMethodCode',
		align: 'center',
		width: '8%',
		render: (record) => {
			const colorMap = {
				ACH: 'green',
				AUTODRAFT: 'orange',
				CHECK: 'yellow',
				IWIRE: 'purple',
				MANUAL: 'red',
				NTCHECK: 'teal',
				ONLINE: 'turquoise',
				WIRE: 'blue'
			};
			return <Tag color={colorMap[record]}>{record && record.toUpperCase()}</Tag>;
		}
	}
];

export const columnsConfig = Config.map((e) => {
	// return { ...e, ...{ sorter: !e.title || e.title === 'Alert' ? false : true } };
	return {
		...e,
		...{
			sorter: true
		}
	};
});
