import { CheckCircleIcon } from '@heroicons/react/solid';
import { Button, message, Popconfirm, Popover } from 'antd';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FilePdfOutlined } from '@ant-design/icons';
import { deCamel } from '@/lib/util';
import { editableStatus } from '@/lib/static';
import { useUser } from '@auth0/nextjs-auth0';
import { useQueryClient } from '@tanstack/react-query';
import {
	InformationCircleIcon,
	ClockIcon,
	PencilAltIcon,
	PlayIcon,
	DocumentDownloadIcon,
	UserCircleIcon,
	CashIcon
} from '@heroicons/react/outline';

export default function EditPanel({ record, tableId }) {
	const router = useRouter();
	const queryClient = useQueryClient();
	const { user, error, isLoading } = useUser();
	const [visible, setVisible] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [downloadsVisible, setDownloadsVisible] = useState(false);

	const handleDownloadsVisibleChange = (newVisible) => {
		setDownloadsVisible(newVisible);
	};

	const handleOk = (record) => {
		console.log(record._id);
		setConfirmLoading(true);
		handleExectue(record);
	};

	const handleExectue = async (batch) => {
		if (!batch || !batch.Records) {
			console.error('Cant Process Empty Batch');
			return setVisible(false);
		}

		const resp = await fetch(`/api/batch/execute`, {
			method: 'POST',
			body: JSON.stringify({
				id: batch._id,
				currentStatus: batch.Status,
				targetRecords: [...batch.Records.map((x) => x._id)]
			})
		});

		if (!resp.ok || resp.status !== 200) {
			setVisible(false);
			setConfirmLoading(false);
			queryClient.invalidateQueries(tableId);
			message.error(resp.message ?? 'An error occured while attempting to process this job.');
		}

		const response = await resp.json();

		setVisible(false);
		setConfirmLoading(false);
		queryClient.invalidateQueries(tableId);

		if (response.error) {
			message.error(response.message ?? 'An error occured while attempting to process this job.');
		} else {
			message.success('Success');
		}
	};

	const GetAction = ({ record }) => {
		switch (record.Status) {
			case 'Corrected':
			case 'Created':
				if (record?.Workflow == 'M' || record?.Workflow == 'AD') {
					return (
						<div title="User Manual Process">
							<UserCircleIcon className="tool-icons text-slate-400" />
						</div>
					);
				} else {
					return (
						<div onClick={() => setVisible(true)} title="Process Job" className="cursor-pointer">
							<PlayIcon className={`tool-icons text-blue-400`} />
						</div>
					);
				}
			case 'Ready To Print':
				return (
					<div title="Download PDF">
						<Popover
							content={
								<ul>
									{record?.Files.map((e, i) => {
										return (
											<li key={i} className="my-2">
												<a
													href={`/api/sign/${encodeURIComponent(e.Url)}`}
													className="text-sky-500 underline hover:underline hover:font-bold"
													target="_blank"
													rel="noreferrer"
												>
													{deCamel(e.FileType)}
												</a>
											</li>
										);
									})}
								</ul>
							}
							title="Downloads"
							trigger="click"
							open={downloadsVisible}
							onOpenChange={handleDownloadsVisibleChange}
						>
							<FilePdfOutlined className={`ant-tool-icons text-red-500`} />
						</Popover>
					</div>
				);
			case 'Posted':
			case 'Confirmed':
			case 'Complete':
				return (
					<div title="Job Posted">
						<CheckCircleIcon className={`tool-icons text-green-500`} />
					</div>
				);
			case 'Currency Conversion':
				return (
					<div title="User Manual Process">
						<UserCircleIcon className="tool-icons text-slate-400" />
					</div>
				);
			default:
				return (
					<div title="Job Processing">
						<ClockIcon className={`tool-icons text-slate-400`} />
					</div>
				);
		}
	};

	return (
		<>
			<div className="flex justify-center">
				{record.Error ? (
					<div className="flex mx-auto">
						<Popover content={<div>{record.Error}</div>} placement="left">
							<Button type="primary" className="danger-btn">
								Error
							</Button>
						</Popover>
					</div>
				) : (
					<>
						<Link href={`${router.pathname}/${record._id}`} passHref>
							<div className="text-yellow-500 cursor-pointer" title="Edit">
								{editableStatus.includes(record.Status) &&
								record.Workflow != 'M' &&
								record.Workflow != 'AD' ? (
									record.Status.includes('Currency Conversion') ? (
										<CashIcon className="tool-icons text-amber-500" />
									) : (
										<PencilAltIcon className={`tool-icons text-slate-600`} />
									)
								) : (
									<InformationCircleIcon className={`tool-icons`} />
								)}
							</div>
						</Link>

						<a href={`/api/batch/export/${record._id}`} rel="noreferrer" target="_blank">
							<div className="text-indigo-300 cursor-pointer" title="Download CSV">
								<DocumentDownloadIcon className={`tool-icons`} />
							</div>
						</a>

						<Popconfirm
							title={<span className="font-bold">Ready to process this job?</span>}
							open={visible}
							onConfirm={() => handleOk(record)}
							okButtonProps={{ loading: confirmLoading }}
							onCancel={() => setVisible(false)}
						>
							<GetAction record={record} />
						</Popconfirm>
					</>
				)}
			</div>
		</>
	);
}
